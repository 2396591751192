
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import { Hero, Bids, Top_collection, Tranding_category, NewseLatter, } from "../../components/component";
import Meta from "../../components/Meta";
const Home_1 = () => {
    return (<main>
      <Meta title="Home"/>
      <Hero />
      
      <Bids />
      <Top_collection />
      <Tranding_category />
      {/* <NewseLatter /> */}
    </main>);
};
export default Home_1;

    async function __Next_Translate__getStaticProps__18dc829eea6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/home_1',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829eea6__ as getStaticProps }
  