
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import Home_13 from "./home/home_13";
import ComingSoon from "./home/coming_soon";
export default function Home() {
    const { scrollRef } = useContext(UserContext);
    useEffect(() => {
        window.scrollTo(0, scrollRef.current.scrollPos);
        const handleScrollPos = () => {
            scrollRef.current.scrollPos = window.scrollY;
        };
        window.addEventListener("scroll", handleScrollPos);
        return () => {
            window.removeEventListener("scroll", handleScrollPos);
        };
    });
    return (<>
      <Home_1 />
      {/* <ComingSoon /> */}
    </>);
}

    async function __Next_Translate__getStaticProps__18dc829ef70__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829ef70__ as getStaticProps }
  