
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import { Hero, Bids, Top_collection, Tranding_category, NewseLatter, } from "../../components/component";
import Meta from "../../components/Meta";
import ComingSoonComponent from "../../components/ComingSoonComponent";
const ComingSoon = () => {
    return (<main>
      <Meta title="Coming Soon"/>
      <ComingSoonComponent />
    </main>);
};
export default ComingSoon;

    async function __Next_Translate__getStaticProps__18dc829ee9d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/coming_soon',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829ee9d__ as getStaticProps }
  