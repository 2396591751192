import Image from "next/image";
import Link from "next/link";
import { useSelector } from "react-redux";

const ComingSoonComponent = () => {

  return (
    <section className="relative w-screen pt-20 md:pt-32 h-screen bg-[url('/images/covers/cover.jpg')] object-cover bg-no-repeat">
      
    </section>
  );
};

export default ComingSoonComponent;
